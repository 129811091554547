<template>
  <MainContent :Title="'DANH SÁCH KIỂM KÊ'">
    <div class="search-box">
      <div class="filter is-gapless columns mar-10">
        <div class="column is-10">
          <div class="columns is-gapless dropdown-search-custom">
            <b-field class="column is-3">
              <b-datepicker
                :locale="locale"
                placeholder="Từ ngày đến ngày"
                icon-right="calendar-today"
                :years-range="[-100, 100]"
                trap-focus
                v-model="dates"
                range
                :max-date="maxDate"
              >
              </b-datepicker>
            </b-field>
            <b-field class="column is-2">
              <b-select
                placeholder="Trạng thái"
                v-model="filter.status"
                expanded
                class="status-box"
              >
                <option
                  v-for="option in listStatus"
                  :value="option.Code"
                  :key="option.Code"
                >
                  {{ option.Name }}
                </option>
              </b-select>
            </b-field>
            <b-field class="column is-3">
              <v-select
                ref="model"
                placeholder="Chọn PGD"
                class="style-chooser"
                label="Name"
                v-model="filter.shopCode"
                :options="listShop"
                :reduce="(option) => option.Code"
                :disabled="shopCodeSelected != Enum.OFFICE.HO"
                @input="loadseletedShop"
              >
                <div slot="no-options">Không có kết quả phù hợp</div>
              </v-select>
            </b-field>
             <b-field class="column is-2">
                <v-select
                  ref="model"
                  placeholder="Thủ quỹ"
                  class="style-chooser"
                  label="FullName"
                  v-model="filter.cashier"
                  :options="lstUser"
                  :reduce="(option) => option.UserName"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
               <b-field class="column is-2">
                <v-select
                  ref="model"
                  placeholder="Lần kiểm kê"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.numberInven"
                  :options="lstType"
                  :reduce="(option) => option.Code"
                  
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
          </div>
        </div>
        <div class="column is-1">
          <p class="control">
            <b-button class="btn-search" @click="onSearch">
              <img src="@/assets/img/search.png" alt />
            </b-button>
          </p>
        </div>
        <div
          class="column is-1"
          v-if="
            checkAccessRole(
              $getConst('MODULE').FundInventorySec,
              $getConst('ACTION').FundInventorySec.Export
            )
          "
        >
          <div class="mar-top-5 export-excel" @click="onExportExcel()">
            <img src="@/assets/img/excel.svg" alt /> <span>Xuất Excel</span>
          </div>
        </div>
      </div>
    </div>

    <PagingItem :filter="filter" :total="total" @onPageChange="pageChange" />

    <InventoryInfo :data="inventorySecs" :offset="filter.offset">
    </InventoryInfo>

    <PagingItem :filter="filter" :total="total" @onPageChange="pageChange" />
  </MainContent>
</template>

<script>
import PagingItem from "@/modules/common/components/PagingItem.vue";
import MainContent from "@/modules/main/components/MainContent.vue";
// import FromToDate from '@/modules/common/components/FromToDate.vue';
import InventoryInfo from "@/modules/fundinventorysec/components/InventoryInfo.vue";
import FundInventorySecService from "@/modules/fundinventorysec/services/FundInventorySecService.vue";
import ConvertDateTime from "@/helpers/ConvertDatetime.vue";
import store from "@/store";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import Enum from "@/helpers/Enum.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import UserService from "@/modules/user/services/UserService.vue";

export default {
  components: { MainContent, InventoryInfo, PagingItem },
  data() {
    const today = new Date();
    return {
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      lstType:[
        {
          Code:"01",
          Name:"Tất cả"
        },
        {
          Code:"02",
          Name:"Lần gần nhất"
        }
      ],
      filter: {
        offset: 0,
        limit: 10,
        fromDate: null,
        toDate: null,
        status: "All",
        shopCode:null,
        cashier:null,
        numberInven:"01"
      },
      lstUser:[],
      dates: [],
      locale: "vi-ve",
      total: 0,
      inventorySecs: [],
      listStatus: this.$getConst("INVENTORY_SEC_STATUS_SEARCH"),
      listShop: [],
      shopCodeSelected: null,
      Enum: Enum,
       filUser: {
        ShortName: null,
      }
    };
  },
  async mounted() {
    let _self = this;
    _self.checkURL();
     let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
    _self.filter.shopCode = shopSelected.Code;
    await _self.GetListPGD();
    await _self.onSearch();
    await _self.SetDefaultData();
  },
  methods: {
      async SetDefaultData() {
      let _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.filUser.ShortName = shopSelected.ShortNameV2;
      _self.GetEmpoyee();
    },
      loadseletedShop(val) {
      let _self = this;
      _self.filUser.ShortName = _self.listShop.filter(
        (x) => x.Code == val
      )[0].Code;
      _self.GetEmpoyee();
      },
     async GetEmpoyee() {
      let _self = this;
      //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let result = await UserService.Get_ListEmployeePGD(
        _self.filUser.ShortName
      );
      _self.lstUser = result;
    },
    async GetListPGD() {
      let _self = this;
      
      var result = await FundTransferService.Get_ListPGDNew();
      _self.listShop = result.Data;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.shopCodeSelected = shopSelected.BusinessOfficeCode;
     
      _self.$parent.isLoading = false;
    },
    async onSearch() {
      let filterModel = this.getFilter();
      let _self = this;
      _self.$parent.isLoading = true;
       let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
       if(_self.shopCodeSelected!=Enum.OFFICE.HO)
        _self.filter.shopCode = shopSelected.Code;
      if (_self.dates[0] != null) {
        _self.filter.fromDate = ConvertDateTime.ConvertDatetimeToUnix(
          _self.dates[0]
        );
        _self.filter.toDate = ConvertDateTime.ConvertDatetimeToUnix(
          _self.dates[1]
        );
        let cal = ConvertDateTime.CalcularDate(_self.dates[1], _self.dates[0]);
        if (cal > 30) {
          ToastHelper.OpenToast(
            "Bạn không thể xem dữ liệu vượt quá 30 ngày",
            "is-warning"
          );
          return;
        }
      }
      let result = await FundInventorySecService.Post_GetInventorySecs(
        filterModel
      );
      if (result && result != null) {
        this.inventorySecs = result.Data;
        this.total = result.Total;
      }
      _self.$parent.isLoading = false;
    },
    async onExportExcel() {
      let _self=this;
       _self.$parent.isLoading = true;
      let filterModel = this.getFilter();
      let result = await FundInventorySecService.Post_ExportExcel(filterModel);
      this.exportExcel(result);
       _self.$parent.isLoading = false;
    },
    getFilter() {
      let fromDate = null;
      let toDate = null;
      if (this.dates != null && this.dates.length == 2) {
        fromDate = this.dates[0];
        toDate = this.dates[1];
      }
      return {
        offset: this.filter.offset,
        limit: this.filter.limit,
        fromDate:
          fromDate != null
            ? ConvertDateTime.ConvertDatetimeToUnix(fromDate)
            : null,
        toDate:
          toDate != null ? ConvertDateTime.ConvertDatetimeToUnix(toDate) : null,
        status: this.filter.status,
        shops: store.state.shops,
        shopCode:this.filter.shopCode,
        cashier:this.filter.cashier,
        numberInven:this.filter.numberInven,
      };
    },
    async pageChange() {
      await this.onSearch();
    },
  },
};
</script>

<style lang="scss">
.search-box {
  padding: 15px 20px 0px 20px !important;

  .btn-search {
    margin-left: 7px;
    border: 1px solid $color-D0D8E1 !important;
  }
}
</style>