<template>
  <div class="paging-item-container">
    <span>
      Hiển thị
      <b
        >{{ total > 0 ? filter.offset + 1 : 0 }} -
        {{
          filter.offset + filter.limit > total
            ? total
            : filter.offset + filter.limit
        }}</b
      >
      trên tổng số
      <b>{{ total }}</b> bản ghi
    </span>

    <div class="right-side">
      <button v-if="filter.offset == 0">
        <img src="@/assets/img/prev-de.png" />
      </button>
      <button
        v-if="filter.offset != 0"
        @click="
          onPrevPage();
        "
      >
        <img src="@/assets/img/prev-ac.png" />
      </button>
      <button
        v-if="filter.offset + filter.limit < total"
        @click="
          onNextPage();
        "
      >
        <img src="@/assets/img/next-ac.png" />
      </button>
      <button v-if="filter.offset + filter.limit >= total">
        <img src="@/assets/img/next-de.png" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filter", "total"],
  methods: {
    onPrevPage() {
      this.filter.offset -= this.filter.limit;
      this.$emit("onPageChange");
    },
    onNextPage() {
      this.filter.offset += this.filter.limit;
      this.$emit("onPageChange");
    }
  }
};
</script>
