<template>
    <div class="modal-content">
        <div class="modal-main">
            <div class="modal-header">
                <span class="close-modal" @click="$emit('close')">
                    <img src="@/assets/img/letter-x 2.png" alt />
                </span>
                <p class="item-title">Ảnh đính kèm</p>
            </div>
            <div class="main-content-popup">
                <ValidationObserver ref="observer">
                    <div class="boder-content">
                        <b-upload
                            v-model="images"
                            multiple
                            accept=".png, .jpg, .jpeg"
                            @input="uploadImages"
                        >
                            <div class="inventory-sec-popup-btn-image-attach">
                                <span>Ảnh đính kèm</span>
                                <img src="@/assets/img/upload.svg" alt />
                            </div>
                        </b-upload>
                        
                        <ValidationProvider
                            :rules="{ required: false }"
                            name="Ảnh đính kèm"
                            v-slot="{ errors }"
                            :vid="uuidGen()"
                        >
                            <b-field :type="{ 'is-danger': errors[0] }" :message="errors" custom-class="is-small">
                                <!-- <viewer
                                    v-model="imagesMain"
                                    :images="imagesMain"
                                    class="inventory-sec-popup-image-box"
                                >
                                    <ImageComponent
                                        v-for="(item, index) in imagesMain"
                                        :key="index"
                                        :file="item"
                                        :idx="index"
                                        @deleteFile="onDeleteImage"
                                    >
                                    </ImageComponent>
                                </viewer> -->
                                <div v-viewer="options" class="inventory-sec-popup-image-box images clearfix">
                                    <ImageComponent
                                        v-for="(item, index) in imagesMain"
                                        :key="index"
                                        :file="item"
                                        :idx="index"
                                        @deleteFile="onDeleteImage"
                                    />
                                    
                                </div>    
                            </b-field>
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
            </div>
            <div class="btn-save-image">
                <b-button
                    type="is-success"
                    @click="onSaveImage"
                >Lưu ảnh</b-button>
            </div>
        </div>
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
    </div>
</template>

<script>
import UploadAWS from "@/helpers/UploadAWS.vue";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ImageComponent from '@/modules/common/components/ImageComponent.vue';
import FundInventorySecService from '@/modules/fundinventorysec/services/FundInventorySecService.vue';
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;

export default {
    props: ["fundSecData"],
    components: { ImageComponent },
    data() {
        return {
            options: {
                toolbar: true,
                url: 'data-source',
            },
            images: [],
            imagesMain: [],
            imagesDeleted: [],
            isLoading: false
        }
    },
    mounted() {
        if (this.fundSecData.Images != null && this.fundSecData.Images.length > 0) {
            for (let i = 0; i < this.fundSecData.Images.length; i++) {
                this.imagesMain.push(this.fundSecData.Images[i]);
            }
        }
    },
    methods: {
        uuidGen() {
          return uuidv4();
        },
        async uploadImages() {
            this.isLoading = true;
            for (let i = 0; i < this.images.length; i++) {
                let item = this.images[i];
                let now = moment();
                let folder = "Images/InventorySec";
                let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
                let fileName = `${now.format("YYYYMMDD")}_${now.format("HHmmss")}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(16)}`;
                let pathFull = `${pathRoot}/${fileName}.${item.name.slice(item.name.lastIndexOf(".") + 1)}`;

                await UploadStatic.PutFileS3(
                    pathFull,
                    item,
                    fileName,
                );
                //if (result) {
                    let file = {
                        Id: 0,
                        Path: amzUrl + pathFull,
                        FileName: fileName
                    };
                    this.imagesMain.push(file);
                //}
            }
            this.images = [];
            this.isLoading = false;
        },
        async onDeleteImage(file) {
            this.isLoading = true;
            // await UploadAWS.deleteFile(file.Path);
            for (let i = 0; i < this.imagesMain.length; i++) {
                let item = this.imagesMain[i];
                if (item.Path == file.Path) {
                    let index = this.imagesMain.indexOf(item);
                    this.imagesDeleted.push(item);
                    this.imagesMain.splice(index, 1);
                }
            }

            this.isLoading = false;
        },
        async onSaveImage() {
            this.$refs.observer.validate().then(async result => {
                if (!result) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.observer.errors)
                                            .map(([key, value]) => ({ key, value }))
                                            .filter(error => error["value"].length);
                        this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                    }, 100);

                    return;
                }

                this.fundSecData.Images = this.imagesMain;
                var resultUpdate = FundInventorySecService.Put_UpdateUploadImages(this.fundSecData);
                if (resultUpdate) {
                    if (this.imagesDeleted != null && this.imagesDeleted.length > 0) {
                        for (let i = 0; i < this.imagesDeleted.length; i++) {
                            await UploadAWS.deleteFile(this.imagesDeleted[i].Path);
                        }
                    }

                    this.$emit('close');
                }
            });
        }
    }
}
</script>

<style lang="scss">
.boder-content {
        .inventory-sec-popup-btn-image-attach {
        display: flex;
        border: 1px solid $color-DBDFE5;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
        width: 100%;
        padding: 5px;

        span {
            padding-right: 10px;
            border-right: 1px solid $color-DBDFE5;
        }

        img {
            margin-left: 10px;
        }
    }


}

.btn-save-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

</style>
