<template>
  <div class="inventory-info-container">
    <b-table
      :data="data"
      ref="table"
      hoverable
      custom-detail-row
      bordered
      striped
    >
      <b-table-column
        :label="columnsVisible['no'].title"
        centered
        v-slot="props"
      >
        {{ offset + props.index + 1 }}
      </b-table-column>
      <b-table-column
        field="Time"
        :label="columnsVisible['time'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ convertStringDatetime(props.row.Time) }}
      </b-table-column>
      <b-table-column
        field="TotalMoneyCashNote"
        :label="columnsVisible['moneyCashNote'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.TotalMoneyCashNote) }}
      </b-table-column>
      <b-table-column
        field="TotalMoneyActual"
        :label="columnsVisible['moneyActual'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.TotalMoneyActual) }}
      </b-table-column>
      <b-table-column
        field="Collect"
        :label="columnsVisible['Collect'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.Collect) }}
      </b-table-column>
      <b-table-column
        field="CollectError"
        :label="columnsVisible['CollectError'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.CollectError) }}
      </b-table-column>
      <b-table-column
        field="Spend"
        :label="columnsVisible['Spend'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.Spend) }}
      </b-table-column>
      <b-table-column
        field="SpendErrors"
        :label="columnsVisible['SpendError'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.SpendErrors) }}
      </b-table-column>
      <b-table-column
        field="TotalMoney"
        :label="columnsVisible['money'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.TotalMoney) }}
      </b-table-column>
      <b-table-column
        field="TotalMoneyProcessing"
        :label="columnsVisible['moneyProcess'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ formatCurrency(props.row.TotalMoneyProcessing) }}
      </b-table-column>
      <b-table-column
        field="Note"
        :label="columnsVisible['Note'].title"
        sortable
        centered
        v-slot="props"
        width="200"
      >
        {{ props.row.Note }}
      </b-table-column>
      <b-table-column
        field="NumberOfInventory"
        :label="columnsVisible['NumberOfInventory'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.NumberOfInventory }}
      </b-table-column>
          <b-table-column
        field="CreatedFullName"
        :label="columnsVisible['CreatedFullName'].title"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.CreatedFullName }}
      </b-table-column>
      <b-table-column
        field="Status"
        :label="columnsVisible['status'].title"
        sortable
        centered
        v-slot="props"
      >
        <StatusComponent
          :statusName="
            props.row.Status == $getConst('INVENTORY_SEC_STATUS_SEARCH')[2].Code
              ? $getConst('INVENTORY_SEC_STATUS_SEARCH')[2].Name
              : $getConst('INVENTORY_SEC_STATUS_SEARCH')[1].Name
          "
          :isOrange="
            props.row.Status != $getConst('INVENTORY_SEC_STATUS_SEARCH')[2].Code
          "
          :isGreen="
            props.row.Status == $getConst('INVENTORY_SEC_STATUS_SEARCH')[2].Code
          "
        />
      </b-table-column>
      <b-table-column
        field="Id"
        :label="columnsVisible['func'].title"
        centered
        v-slot="props"
        width="70"
      >
        <b-dropdown class="func-container" position="is-top-left">
          <template #trigger>
            <a role="button">
              <span>
                <img src="@/assets/img/circledoted.svg" alt />
              </span>
            </a>
          </template>
          <p class="txt-align-center func-title">CHỨC NĂNG</p>
          <b-dropdown-item
            v-if="
              props.row.Status != 'Approved' &&
              props.row.Shop.Code == shopCode &&
              props.row.CurrentUser.Code != currentUsername
            "
            paddingless
            @click="onApprove(props.row.Id)"
          >
            <div class="func-item btn-approve">
              <img src="@/assets/img/tick.svg" alt />
              <span>Duyệt kiểm kê</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item paddingless @click="onUploadImage(props.row.Id)">
            <div class="func-item btn-normal">
              <img src="@/assets/img/folder-image.svg" alt />
              <span>Ảnh</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item paddingless @click="onPrint(props.row.Id)">
            <div class="func-item btn-normal">
              <img src="@/assets/img/printer.svg" alt />
              <span>In</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item paddingless @click="onPDF(props.row.Id)">
            <div class="func-item btn-normal">
              <img src="@/assets/img/pdf.svg" alt />
              <span>PDF</span>
            </div>
          </b-dropdown-item>
               <b-dropdown-item paddingless @click="onExport(props.row.Id)">
            <div class="func-item btn-normal">
              <img src="@/assets/img/excel.svg" alt />
              <span>Excel</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>

    <div
      v-if="fundSecData != null && fundSecData.Shop != null"
      id="printInventorySec"
      style="display: none"
    >
      <PrintInventorySec
        v-if="isShows"
        :fundSecData="fundSecData"
        :timeStr="timeStr"
        :lstTableSpendError="lstTableSpendError"
        :lstTableRevError="lstTableRevError"
        :collect="collect"
        :spend="spend"
        :RevErrorTotal="fundSecData.SpendErrors"
        :SpendErrorTotal="fundSecData.CollectError"
      />
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'kiem_ke_' + timeStr"
      :pdf-quality="2"
      :manual-pagination="false"
      ref="html2Pdf"
      :htmlToPdfOptions="htmlToPdfOptions"
      pdf-content-width="1500px"
    >
      <section slot="pdf-content">
        <PrintInventorySec
          v-if="fundSecData != null && fundSecData.Shop != null"
          :fundSecData="fundSecData"
          :timeStr="timeStr"
          :lstTableSpendError="lstTableSpendError"
          :lstTableRevError="lstTableRevError"
          :collect="collect"
          :spend="spend"
          :RevErrorTotal="fundSecData.SpendErrors"
          :SpendErrorTotal="fundSecData.CollectError"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import PrintInventorySec from "@/modules/fundinventorysec/components/PrintInventorySec.vue";
import StatusComponent from "@/modules/common/components/StatusComponent.vue";
// import InventoryItem from '@/modules/fundinventorysec/components/InventoryItem.vue';
import FundInventorySecService from "@/modules/fundinventorysec/services/FundInventorySecService.vue";
import ConvertDatetime from "@/helpers/ConvertDatetime.vue";
import InventorySecImagePopup from "@/modules/fundinventorysec/components/InventorySecImagePopup.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import store from "@/store";

export default {
  components: { PrintInventorySec, StatusComponent },
  props: ["data", "offset"],
  data() {
    return {
      isShows: false,
      lstTableSpendError: [],
      lstTableRevError: [],
      denominationsMoneys: [],
      fundSecData: {},
      collect: [],
      spend: [],
      timeStr: "",
      shopCode: store.state.shopSelected.Code,
      currentUsername: store.state.user.Profile.UserName,
      columnsVisible: {
        no: { title: "#", display: true },
        time: { title: "Ngày", display: true },
        moneyCashNote: { title: "Sổ quỹ tiền mặt", display: true },
        moneyActual: { title: "Kiểm kê thực tế", display: true },
        moneyDiff: { title: "Chênh lệch thực thu chi", display: true },
        money: { title: "Quỹ thực tế so với sổ sách", display: true },
        status: { title: "Trạng thái", display: true },
        reason: { title: "Lý do", display: true },
        func: { title: "", display: true },
        moneyProcess: { title: "Chênh lệch", display: true },
        Note: { title: "Ghi chú", display: true },
        Collect: { title: "Chi chờ xử lý", display: true },
        CollectError: { title: "Số tiền thiếu", display: true },
        Spend: { title: "Thu chờ xử lý", display: true },
        SpendError: { title: "Số tiền thừa", display: true },
        NumberOfInventory: { title: "Lần kiểm kê", display: true },
        CreatedFullName: { title: "Người kiểm kê", display: true },
      },
      htmlToPdfOptions: {
        margin: 10,

        filename: "kiem_ke_",

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a2",
          orientation: "portrait",
        },
      },
    };
  },
  async mounted() {
    let resultDenominationsMoney =
      await FundInventorySecService.GetListCategoryDenominationsMoney();
    if (resultDenominationsMoney && resultDenominationsMoney != null) {
      this.denominationsMoneys = resultDenominationsMoney;
    }
  },
  methods: {
    convertdata() {
      let _self = this;
      console.log(_self.fundSecData);
      _self.lstTableSpendError = _self.fundSecData.SpendError.filter(
        (x) => x.Type == 1
      );
      _self.lstTableRevError = _self.fundSecData.SpendError.filter(
        (x) => x.Type == 2
      );
      _self.collect = _self.fundSecData.RevTran.filter((x) => x.Type == 1);
      _self.spend = _self.fundSecData.RevTran.filter((x) => x.Type == 2);
      console.log(_self.lstTableSpendError, _self.lstTableRevError);
      _self.isShows = true;
    },
    convertStringDatetime(time) {
      return ConvertDatetime.ConvertUnixToDatetime(time);
    },
  async  onPrint(Id) {
      let self = this;
      if (self.data != null && self.data.length > 0) {
       await self.GetById(Id);
        
        self.timeStr = self.getDayString(
          ConvertDatetime.ConvertUnixToDateFormat(self.fundSecData.Time)
        );
        self.$nextTick(function () {
          self.$htmlToPaper("printInventorySec");
        });
      }
    },
   async onPDF(id) {

        if (this.data != null && this.data.length > 0) {
          //this.fundSecData = this.data.find((x) => x.Id == id);
          await this.GetById(id);
        
          this.timeStr = this.getDayString(
            ConvertDatetime.ConvertUnixToDateFormat(this.fundSecData.Time)
          );
          this.htmlToPdfOptions.filename = "kiem_ke_" + this.timeStr;
          this.$refs.html2Pdf.generatePdf();
        }
 
    },
  async  onExport(id)
    {
      let _self=this;
      var obj={
        Id:id
      };
      let result =await  FundInventorySecService.Post_ExportExcel_One(obj);
      _self.exportExcelOne(result);
    }
    ,
    async onApprove(id) {
      if (this.data != null && this.data.length > 0) {
        this.fundSecData = this.data.find((x) => x.Id == id);
        this.fundSecData.Modifier = {
          Code: store.state.user.Profile.UserName,
          Name: store.state.user.Profile.FullName,
        };
        let result =
          await FundInventorySecService.Put_UpdateInventorySecApporve(
            this.fundSecData
          );
        if (result) {
          ToastHelper.OpenToast("Duyệt kiểm kê thành công", "is-success");
        } else {
          ToastHelper.OpenToast("Duyệt kiểm kê thất bại", "is-warning");
        }
      }
    },
    onUploadImage(id) {
      if (this.data != null && this.data.length > 0) {
        this.fundSecData = this.data.find((x) => x.Id == id);
        this.openUploadImagePopup(this.fundSecData);
      }
    },
    openUploadImagePopup(fundSecData) {
      this.$buefy.modal.open({
        parent: this,
        component: InventorySecImagePopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          fundSecData: fundSecData,
        },
        events: {
          // onLoadCustomerInfoEvent: () => this.onLoadCustomerInfo()
        },
      });
    },
      exportExcelOne(data) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute('download', 'danh_sach_giao_dich_treo.xlsx');
      document.body.appendChild(link);
      link.click();
    }
    ,
    async GetById(Id) {
      let _self = this;
      _self.$parent.$parent.isLoading=true;
      let result = await FundInventorySecService.Get_GetById(Id);
      _self.fundSecData=result;
      this.convertdata();
      _self.$parent.$parent.isLoading=false;
    }
  },
};
</script>

<style lang="scss" scoped>
.inventory-info-container {
  padding: 10px;

  .func-container {
    .func-title {
      padding: 5px;
      background: $color-lighter-gray;
      border-bottom: $border-default;
    }

    .func-item {
      display: flex;
      align-items: center;
      padding: 5px;

      img {
        margin-right: 10px;
      }

      &.btn-approve {
        color: white;
        background: #2cb66b;
        border: 1px solid #00833e;
        box-sizing: border-box;
      }

      &.btn-normal {
        color: #007aff;
        border: 1px solid #dddddd;
        box-sizing: border-box;
      }
    }
  }
}
</style>