<template>
    <span
        :class="{'txt-color-orange': isOrange, 'txt-color-red': isRed, 'txt-color-green': isGreen}"
    >
        <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
        <b class="pawn-status">{{ statusName }}</b>
    </span>
</template>

<script>
export default {
    props: ["statusName", "isOrange", "isRed", "isGreen"],
}
</script>

<style lang="scss" scoped>
.txt-color-orange {
    color: $color-FDB719;
}

.txt-color-red {
    color: $color-FF3A3A;
}

.txt-color-green {
    color: $color-2CB66B;
}
</style>